import OrderRepository from "../../../../../repositories/OrderRepository";
import RouteName from "../../../../../services/routename";
import DateUtility from "../../../../../utils/date-utility";
import PopupUtility from "../../../../../utils/popup-utility";
import {
  setEndDateFilter,
  setHasMoreItems,
  setInvoiceDetail,
  setIsEmptyList,
  setPage,
  setList,
  setLoadingData,
  setSearch,
  setShowInvoiceModal,
  setShowTrackingModal,
  setSort,
  setStartDateFilter,
  setTrackingDetail,
  setModalComplete,
  setDataComplete,
  setDataCancel,
  setModalCancel,
  setLoadingButton,
} from "./Reducer";
import ActionSidebar from "../../../../../components/layout/sidebar/redux/Action";

const getList = async (
  dispatch,
  { currentList, search, sort, startDateFilter, endDateFilter, page }
) => {
  dispatch(setSearch(search));
  dispatch(setSort(sort));
  dispatch(setStartDateFilter(startDateFilter));
  dispatch(setEndDateFilter(endDateFilter));
  dispatch(setLoadingData(true));
  dispatch(setIsEmptyList(false));
  let list;
  let data = {
    status: "",
    page: page,
    size: 10,
  };

  if (search) {
    data.search = search;
  }

  if (startDateFilter) {
    data.startDate = DateUtility.dateFormatApi(startDateFilter);
  } else {
    data.startDate = "";
  }

  if (endDateFilter) {
    data.endDate = DateUtility.dateFormatApi(endDateFilter);
  } else {
    data.endDate = "";
  }

  if (sort) {
    data.orderBy = sort;
  } else {
    data.orderBy = "";
  }

  if (currentList) {
    list = currentList;
  } else {
    list = [];
  }

  const response = await OrderRepository.getListOrder(data);
  if (!response.error) {
    dispatch(setLoadingData(false));
    dispatch(setList([...list, ...response.data]));
    if (response.data.length > 0) {
      dispatch(setPage(page + 1));
    } else {
      dispatch(setHasMoreItems(false));
      if (list.length === 0 && response.data.length === 0) {
        dispatch(setIsEmptyList(true));
      }
    }
  }
};

const showInvoiceModal = (dispatch, { showModal, invoiceDetail }) => {
  if (showModal) {
    dispatch(setInvoiceDetail(invoiceDetail));
  }
  dispatch(setShowInvoiceModal(showModal));
};

const showTrackingModal = async (dispatch, { showModal, id }) => {
  if (showModal) {
    dispatch(setLoadingData(true));
    const response = await OrderRepository.getTrackingOrder({
      orderMerchantId: id,
    });
    if (!response.error) {
      dispatch(setTrackingDetail(response.data));
    } else {
      PopupUtility.responseValidate("Failed", response.message);
    }
    dispatch(setLoadingData(false));
  }
  dispatch(setShowTrackingModal(showModal));
};

const setPickUp = async (dispatch, { id }) => {
  dispatch(setLoadingButton(true));
  const response = await OrderRepository.setPickUpOrder({
    orderMerchantId: id,
  });
  if (!response.error) {
    dispatch(setList([]));
    dispatch(setHasMoreItems(true));
    getList(dispatch, { currentList: [], lastId: "", page: 0 });
    PopupUtility.success(
      "Your pickup arrangements are being processed. Our courier will be with you shortly"
    );
    ActionSidebar.getTotalNotif(dispatch);
  } else {
    const message = `
    <p class="text-lg mb-3 font-extrabold">${response.message}</p>
    <p class="text-sm font-bold text-gray-400 tracking-wider">Trace Id : ${response.trace_id}</p>
  `;
    PopupUtility.responseValidate("Failed", message);
  }
  dispatch(setLoadingButton(false));
};

const emptyList = async (dispatch) => {
  dispatch(setList([]));
  dispatch(setHasMoreItems(true));
};

const getResi = async (inv) => {
  const response = await OrderRepository.getResi({
    noInvoice: inv,
  });
  if (!response.error) {
    const convertedString = inv.replace(/\//g, "-");
    const url = RouteName.jnelabel + "/" + convertedString;
    window.open(url, "_blank");
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
};

const showModalComplete = async (dispatch, { showModal, id }) => {
  if(showModal) {
    dispatch(setDataComplete(id));
  }
  dispatch(setModalComplete(showModal))
}
const showModalCancel = async (dispatch, { showModal, id }) => {
  if(showModal) {
    dispatch(setDataCancel(id));
  }
  dispatch(setModalCancel(showModal))
}

const completeButton = async (dispatch, {id, remarks}) => {
  let data = {
    orderMerchantId: id,
    receiverName: remarks,
  };
  const response = await OrderRepository.completeOrder(data);
  if (!response.error) {
    dispatch(setList([]));
    dispatch(setHasMoreItems(true));
    emptyList(dispatch);
    getList(dispatch, { currentList: [], page: 0 });
    PopupUtility.success(
      "Your transaction has been complete"
    );
    ActionSidebar.getTotalNotif(dispatch);
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
}

const CancelButton = async (dispatch, {id, reason}) => {
  let data = {
    orderMerchantId: id,
    reason: reason 
  }
  const response = await OrderRepository.cancelOrder(data);
  if (!response.error) {
    dispatch(setList([]));
    dispatch(setHasMoreItems(true));
    emptyList(dispatch);
    getList(dispatch, { currentList: [], page: 0 });
    PopupUtility.success(
      "Your transaction has been cancelled"
    );
    ActionSidebar.getTotalNotif(dispatch);
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }

}



const Action = {
  getList,
  showInvoiceModal,
  showTrackingModal,
  setPickUp,
  emptyList,
  getResi,
  showModalComplete,
  completeButton,
  showModalCancel,
  CancelButton,
};

export default Action;
