import React, { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { Provider, useSelector } from "react-redux";
import StoreHelper from "../../services/store-helper";
import AllOrder from "./components/AllOrder/AllOrder";
import Cancelled from "./components/Cancelled/Cancelled";
import Complained from "./components/Complained/Complained";
import Completed from "./components/Completed/Completed";
import NewOrder from "./components/NewOrder/NewOrder";
import OnShipment from "./components/OnShipment/OnShipment";
import Received from "./components/Received/Received";
import SelfPickup from "./components/SelfPickup/SelfPickup";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";
import { useLocation } from "react-router-dom";

const Orders = ({ getBreadcrums }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  const MainComponent = () => {
    const t = useTranslation();
    const page = useSelector((state) => state.reducer.page);
    const location = useLocation();
    const subType = location.state ? location.state.subType : "ALL_ORDER";
    useEffect(() => {
      getBreadcrums(t("orders.title"));
      Action.getPage(store, { page: subType });
    }, [subType]);

    const nav = [
      { title: t("orders.all_order"), route: "ALL_ORDER" },
      { title: t("orders.new_order"), route: "NEW_ORDER" },
      { title: t("orders.self_pickup"), route: "SELF_PICKUP" },
      { title: t("orders.on_shipment"), route: "ON_SHIPMENT" },
      { title: t("orders.received"), route: "RECEIVED" },
      { title: t("general.completed"), route: "COMPLETED" },
      { title: t("general.cancelled"), route: "CANCELLED" },
      { title: t("orders.complained"), route: "COMPLAINED" },
    ];

    const Content = () => {
      if (page === "ALL_ORDER") {
        return <AllOrder />;
      } else if (page === "NEW_ORDER") {
        return <NewOrder />;
      } else if (page === "SELF_PICKUP") {
        return <SelfPickup />;
      } else if (page === "ON_SHIPMENT") {
        return <OnShipment />;
      } else if (page === "RECEIVED") {
        return <Received />;
      } else if (page === "COMPLETED") {
        return <Completed />;
      } else if (page === "CANCELLED") {
        return <Cancelled />;
      } else if (page === "COMPLAINED") {
        return <Complained />;
      }
    };

    return (
      <>
        <div className="flex flex-row">
          <div className="flex flex-row items-center px-2 gap-x-2">
            <img
              className="h-full w-6"
              src={
                process.env.REACT_APP_ASSETS_IMAGE + "dashboard-icon-active.svg"
              }
              alt="dashboard-icon-active.svg"
            />
            <h2 className="text-green-primary font-bold">
              {t("orders.title")}
            </h2>
          </div>
        </div>

        <div className="flex items-center pt-2">
          <nav className="bg-gray-primary rounded-xl p-2">
            <ul className="inline-flex items-center -space-x-px">
              {nav.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer"
                  onClick={() => {
                    Action.getPage(store, { page: item.route });
                  }}
                >
                  <div
                    className={`${page === item.route ? "bg-gold-secondary text-white" : ""
                      } block px-6 py-2 rounded-xl drop-shadow-md leading-tight`}
                  >
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="pt-6">
          <Content />
        </div>
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Orders;
