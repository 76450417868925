import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "complaints",
  initialState: {
    selectedItem: {},
    isUploadOpen: false,
    bodyChat: "",
    modalImage: false,
    image: {
      title: "",
      src: "",
    },
    data: [],
    loaderListChat:false
  },
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setIsUploadOpen: (state, action) => {
      state.isUploadOpen = action.payload;
    },
    setBodyChat: (state, action) => {
      state.bodyChat = action.payload;
    },
    setModalImage: (state, action) => {
      state.modalImage = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoaderListChat: (state, action) => {
      state.loaderListChat = action.payload;
    }
  },
});

export const {
  setSelectedItem,
  setIsUploadOpen,
  setBodyChat,
  setModalImage,
  setImage,
  setData,
  setLoaderListChat
} = reducerSlice.actions;

export default reducerSlice;

