import Cookies from "js-cookie";
import AccountRepository from "../../../../../repositories/AccountRepository";
import RegisterRepo from "../../../../../repositories/RegisterRepo";
import EncryptDecrypt from "../../../../../services/encrypt-decrypt";
import GeneralUtility from "../../../../../utils/general-utility";
import PopupUtility from "../../../../../utils/popup-utility";
import {
  setAccountDetails,
  setErrorAccountDetails,
  setCodePhone,
  setData,
  setValidateStatus,
  setValidatePass,
  setEdit,
  setShowErrorOn,
  setShowErrorOff,
  setSelfPickupAvailability
} from "./Reducer";
import RouteName from "../../../../../services/routename";

const validate = (dispatch, values) => {
  const errors = {};
  if (!values.photo) {
    errors.photo = "Merchant Photo is required!";
  }
  if (!values.name) {
    errors.name = "Merchant name is required!";
  }
  if (!values.email) {
    errors.email = "Email Merchant is required!";
  }
  if (!values.emailOwner) {
    errors.emailOwner = "Email is required!";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone number is required!";
  } else if (!values.countryCodeId) {
    errors.phoneNumber = "Code National Number is required!";
  } else if (values.phoneNumber.length <= 10) {
    errors.phoneNumber = "Phone number must at least 10 number";
  }
  if (!values.countryCodeId) {
    errors.countryCodeId = "Code National phoneNumber is required!";
  }
  if (!values.address) {
    errors.address = "Address is required!";
  }
  if (!values.provincesId) {
    errors.provincesId = "Province is required!";
  }
  if (!values.citiesId) {
    errors.citiesId = "City is required!";
  }
  if (!values.districtsId) {
    errors.districtsId = "District is required!";
  }
  if (!values.postalCode) {
    errors.postalCode = "Postal Code is required!";
  }
  dispatch(setErrorAccountDetails(errors));
  return errors;
};

const handleChange = (dispatch, e, accountDetails) => {
  const { name, value } = e.target;
  dispatch(setAccountDetails({ ...accountDetails, [name]: value }));
};

const getCodePhone = async (dispatch) => {
  const phoneNumber = await RegisterRepo.getCodePhone();
  dispatch(setCodePhone(phoneNumber));
};

const getData = async (dispatch) => {
  const response = await AccountRepository.getAccountDetails();
  let data = {};
  data.id = response.id;
  data.photo = response.photo;
  data.name = response.name;
  data.email = response.email;
  data.emailOwner = response.ownerEmail;
  data.countryCodeId = response.countryCode.id;
  data.countryCode = response.countryCode.dialCode;
  data.phoneNumber = response.phoneNumber;
  data.address = response.address;
  data.postalCode = response.postalCode;
  data.provinces = response.provinces.name;
  data.cities = response.cities.name;
  data.districts = response.districts.name;
  data.selfPickupAvailability = response.selfPickupAvailability;

  Cookies.set("merchantPhoto", response.photo);

  let accountDetails = {
    name: data.name,
    photo: data.photo,
    email: data.email,
    emailOwner: data.emailOwner,
    phoneNumber: data.phoneNumber,
    countryCodeId: data.countryCodeId,
    address: data.address,
    provincesId: response.provinces.id,
    citiesId: response.cities.id,
    districtsId: response.districts.id,
    postalCode: data.postalCode,
  };
  dispatch(setData(data));
  dispatch(setSelfPickupAvailability(data.selfPickupAvailability))
  dispatch(setAccountDetails(accountDetails));
  dispatch(setValidateStatus(true));
};

const checkValid = async (dispatch, pass, e, accountDetails, originalData, selfPickupAvailability) => {
  e.preventDefault();
  const valid = await AccountRepository.checkPass({ password: pass });
  if (valid.error === false) {
    dispatch(setValidatePass(false));
    handleSubmit(dispatch, accountDetails, originalData, e, pass, selfPickupAvailability);
    dispatch(setShowErrorOff());
  } else {
    dispatch(setShowErrorOn());
    // PopupUtility.responseValidate(valid.message, valid.error_message.password);
  }
};

const handleSubmit = async (dispatch, accountDetails, originalData, e, pass, selfPickupAvailability) => {
  e.preventDefault();
  let data = new FormData();
  data.append("name", accountDetails.name);
  data.append("address", accountDetails.address);
  data.append("citiesId", accountDetails.citiesId);
  data.append("districtsId", accountDetails.districtsId);
  data.append("postalCode", accountDetails.postalCode);
  data.append("provincesId", accountDetails.provincesId);
  data.append("selfPickupAvailability", selfPickupAvailability);
  data.append("password", pass);

  if (accountDetails.phoneNumber === originalData.phoneNumber) {
    data.append("phoneNumber", "");

    if (accountDetails.countryCodeId === originalData.countryCodeId) {
      data.append("countryCodeId", originalData.countryCodeId);
    } else {
      data.append("countryCodeId", accountDetails.countryCodeId);
    }
  } else {
    data.append("phoneNumber", accountDetails.phoneNumber);
    data.append("countryCodeId", accountDetails.countryCodeId);
  }

  if (accountDetails.email === originalData.email) {
    data.append("email", "");
  } else {
    data.append("email", accountDetails.email);
  }

  if (accountDetails.photo !== originalData.photo) {
    data.append(
      "photo",
      GeneralUtility.dataURLtoFile(
        accountDetails.photo,
        accountDetails.namePhoto
      ),
      accountDetails.namePhoto
    );
  }

  const response = await AccountRepository.updateAccountDetail(data);
  if (response.error === false) {
    let origin = { ...originalData };
    if (
      accountDetails.email !== "" &&
      accountDetails.email !== originalData.email
    ) {
      origin.email = accountDetails.email;

      const tokenMerchant = response.data.data.auth.token;
      const refreshTokenMerchant = response.data.data.auth.token;
      Cookies.set("tokenMerchant", EncryptDecrypt.enc(tokenMerchant, { expires: 3 }));
      Cookies.set("refreshTokenMerchant", EncryptDecrypt.enc(refreshTokenMerchant, { expires: 3 }));
    }
    if (
      accountDetails.phoneNumber !== "" &&
      accountDetails.phoneNumber !== originalData.phoneNumber
    ) {
      origin.phoneNumber = accountDetails.phoneNumber;
    }
    if (
      accountDetails.countryCodeId !== "" &&
      accountDetails.countryCodeId !== originalData.countryCodeId
    ) {
      origin.countryCodeId = accountDetails.countryCodeId;
    }
    if (
      accountDetails.photo !== "" &&
      accountDetails.photo !== originalData.photo
    ) {
      origin.photo = accountDetails.photo;
    }
    dispatch(setData(origin));
    dispatch(setEdit({ email: false, phoneNumber: false }));
    const resData = response.data.data;
    if (resData.userId) {
      let user = {};
      user.userId = resData.userId;
      user.merchantsId = resData.merchantsId;
      user.merchantName = resData.merchantName;
      user.merchantCode = resData.merchantCode;
      user.merchantStatus = resData.merchantStatus;
      user.merchantStatusName = resData.merchantStatusName;
      user.email = resData.email;
      user.balance = resData.balance;

      //set session on cookie and encrypt the data
      // Cookies.set("token", EncryptDecrypt.enc(resData.auth.token, { expires: 3 }));
      Cookies.set("userMerchant", EncryptDecrypt.enc(user), { expires: 3 });
      Cookies.set("merchantPhoto", EncryptDecrypt.enc(resData.merchantPhoto), { expires: 3 });
    }
    PopupUtility.success("Changes saved successfully");
    window.location.href = RouteName.accountsettings;
  } else {
    let errMsg = "";
    for (let i = 0; i < Object.keys(response.error_message).length; i++) {
      const err = Object.values(response.error_message)[i];
      errMsg = errMsg.concat(err + "<br/>");
    }
    PopupUtility.responseValidate(response.message, errMsg);
  }
};

const changeSelfpickupCompability = async (dispatch, e) => {
  const checked = e.target.checked;
  dispatch(setSelfPickupAvailability(checked));
}

const Action = {
  validate,
  handleSubmit,
  handleChange,
  getCodePhone,
  getData,
  checkValid,
  changeSelfpickupCompability,
};

export default Action;
