import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../services/store-helper";
import reducerSlice from "./redux/Reducer";
import { useTranslation } from "react-multi-lang";
import React, { useEffect } from "react";
import ChatList from "./components/ChatList";
import DetailChat from "./components/DetailChat";
import Action from "./redux/Action";
import BlankChat from "./components/BlankChat";
import SearchItemChat from "./components/SearchItemChat";
import Cookies from "js-cookie";
import EncryptDecrypt from "../../services/encrypt-decrypt";
import { io } from "socket.io-client";

const MainComponent = ({ getBreadcrums }) => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const selectedItem = useSelector((state) => state.reducer.selectedItem);

  useEffect(() => {
    Action.getList(dispatch);

    // const host = process.env.REACT_APP_URL_WEBSOCKET;
    // console.log("🚀 ~ useEffect ~ host:", host);
    // const getToken = Cookies.get("tokenMerchant");
    // const token = getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;
    // console.log("🚀 ~ useEffect ~ token:", token);

    // // Membuat koneksi ke server dengan custom headers
    // const socket = io(host, {
    //   withCredentials: true,
    //   extraHeaders: {
    //     Authorization: `Bearer ${token}`
    //   }
    // });

    // // Event listener saat koneksi berhasil
    // socket.on('connect', () => {
    //   console.log('Connected to WebSocket server');
    // });

    // // Event listener saat menerima pesan
    // socket.on('message', (data) => {
    //   console.log('Received message:', data);
    // });

    // // Membersihkan koneksi saat komponen unmount
    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  useEffect(() => {
    const title = `${t("chats.title")} > ${t("chats.list")}`;
    getBreadcrums(title);
  }, [getBreadcrums]);

  return (
    <>
      <div className="flex flex-row items-center px-2 gap-x-2">
        <i className="mt-1.5">
          <img
            className="h-full w-10"
            src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon.svg"}
            alt="back-icon"
          />
        </i>
        <h2 className="text-green-primary font-bold">{t("chats.title")}</h2>
      </div>

      <div className="pt-6 h-full flex gap-x-3 items-stretch">
        {/* panel kiri */}
        <div className="basis-4/12 ">
          <SearchItemChat />
          <div className="p-4 bg-white rounded-2xl h-[calc(100%-60px)] overflow-y-auto">
            <ChatList />
          </div>
        </div>

        {/* panel kanan */}
        <div className="basis-8/12 h-full">
          <div className={`basis-8/12 h-full p-4 rounded-2xl flex flex-col ${Object.keys(selectedItem).length > 0 ? "bg-white" : "bg-gray-chat-blank"}`}>
            {Object.keys(selectedItem).length > 0 ? (
              <DetailChat chat={selectedItem} />
            ) : (
              <BlankChat />
            )}
          </div>
        </div>
      </div>
    </>
  );
};


const Chat = ({ getBreadcrums }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  return (
    <Provider store={store}>
      <MainComponent getBreadcrums={getBreadcrums} />
    </Provider>
  );
};

export default Chat;
