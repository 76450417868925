import ChatRepository from "../../../../../repositories/ChatRepository";
import PopupUtility from "../../../../../utils/popup-utility";
import { setMessage, setIsloadingAutoReply } from "./Reducer";

let autoReplyId = null;

const autoReply = (dispatch, e) => {
    const { value } = e.target;
    dispatch(setMessage(value));
};

const getAutoReply = async (dispatch) => {
    dispatch(setIsloadingAutoReply(true));
    const response = await ChatRepository.getAutoReply();
    const data = response.data;
    autoReplyId = data.id;
    const message = data.message;
    dispatch(setMessage(message || ""));
    dispatch(setIsloadingAutoReply(false));
};

const saveAutoReply = async (dispatch, message) => {
    const data = {
        id: autoReplyId,
        message: message
    };
    const response = await ChatRepository.saveAutoReply(data);
    const dataMessage = response.message;
    PopupUtility.success(dataMessage);
    dispatch(setMessage(message));

};

const Action = {
    autoReply,
    getAutoReply,
    saveAutoReply,
};

export default Action;