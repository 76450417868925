import ComplaintRepository from "../../../../repositories/ComplaintRepository";
import RouteName from "../../../../services/routename";
import { setShow, setPageActive, setMenu, setNotifCount } from "./Reducer";

const setMaxMin = async (dispatch, show) => {
  dispatch(setShow(show));
};

const getPageActive = async (dispatch, page) => {
  dispatch(setPageActive(page));
};

const getMenu = async (dispatch, t) => {
  const menu = [
    {
      title: t("dashboard.title"),
      icon: process.env.REACT_APP_ASSETS_IMAGE + "dashboard-icon.svg",
      icon_active:
        process.env.REACT_APP_ASSETS_IMAGE + "dashboard-icon-active.svg",
      route: RouteName.dashboard,
    },
    {
      title: t("products.title"),
      icon: process.env.REACT_APP_ASSETS_IMAGE + "product-icon.svg",
      icon_active:
        process.env.REACT_APP_ASSETS_IMAGE + "product-icon-active.svg",
      route: RouteName.products,
    },
    {
      title: t("orders.title"),
      icon: process.env.REACT_APP_ASSETS_IMAGE + "orders-icon.svg",
      icon_active:
        process.env.REACT_APP_ASSETS_IMAGE + "orders-icon-active.svg",
      route: RouteName.orders,
    },
    {
      title: t("complaints.title"),
      icon: process.env.REACT_APP_ASSETS_IMAGE + "complaint.png",
      icon_active:
        process.env.REACT_APP_ASSETS_IMAGE + "complaint-selected.png",
      route: RouteName.complaint,
    },
    {
      title: t("chats.title"),
      icon: process.env.REACT_APP_ASSETS_IMAGE + "icon-chat-default.svg",
      icon_active:
        process.env.REACT_APP_ASSETS_IMAGE + "icon-chat-pressed.svg",
      route: RouteName.chat,
    },
    {
      title: t("helpcenter.title"),
      icon: process.env.REACT_APP_ASSETS_IMAGE + "helpcenter-icon.svg",
      icon_active:
        process.env.REACT_APP_ASSETS_IMAGE + "helpcenter-icon-active.svg",
      route: RouteName.helpcenter,
    },
  ];

  dispatch(setMenu(menu));
};

const getTotalNotif = async (dispatch) => {
  let response = await ComplaintRepository.getComplaintNotif();

  const response1 = typeof response != "undefined" ? response.data : null;
  const response2 =
    typeof response1 != "undefined" && response1 != null
      ? response1.data
      : null;

  const count = response2;
  
  if (!response.success) {
    dispatch(setNotifCount(count));
  }
};

const Action = {
  setMaxMin,
  getPageActive,
  getMenu,
  getTotalNotif
};

export default Action;
