import React, { useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';
import EncryptDecrypt from '../../services/encrypt-decrypt';

const ChatTest = () => {
    const socketRef = useRef(null);

    useEffect(() => {
        // Token yang ingin Anda kirimkan
        let getToken = Cookies.get("tokenMerchant");
        let token = getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;
        console.log("🚀 ~ useEffect ~ token:", token);

        // Membuat koneksi socket dengan pengaturan header
        socketRef.current = io('ws://18.143.243.84:8085/connect', {
            transports: ['websocket'],
            extraHeaders: {
                Authorization: token,
            },
        });

        // Menangani koneksi dan event lainnya
        socketRef.current.on('connect', () => {
            console.log('Connected to WebSocket');
        });

        socketRef.current.on('message', (data) => {
            console.log('Message received:', data);
        });

        socketRef.current.on('disconnect', () => {
            console.log('Disconnected from WebSocket');
        });

        // Cleanup on component unmount
        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    return (
        <div>
            <h1 className='text-white'>WebSocket Example</h1>
        </div>
    );
};

export default ChatTest;
