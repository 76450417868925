import { useEffect, useRef } from "react";
import StoreHelper from "../../../services/store-helper";
import reducerSlice from "../redux/Reducer";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Action from "../redux/Action";
import ModalImage from "../../../components/ModalImage/ModalImage";
import ChatSender from "./ChatSender";
import BarDetailChat from "./BarDetailChat";

const MainComponent = ({ chat }) => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const modalImage = useSelector((state) => state.reducer.modalImage);
  const image = useSelector((state) => state.reducer.image);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  if (!chat || Object.keys(chat).length === 0) return null;

  return (
    <>
      <div className="flex flex-col bg-white relative h-full">
        {/* bar detail chat */}
        <BarDetailChat chat={chat} />

        {/* chatbox messages */}
        <div
          ref={chatContainerRef}
          className="flex flex-col overflow-y-auto p-2 space-y-4 h-[calc(100%-150px)]">
          <p className="flex items-center justify-center text-xs">{chat.day}</p>

          <div className="flex self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2 gap-5">
            <div className="">{chat.message}</div>
            <div className="self-end text-[8px]">{chat.time}</div>
          </div>

          <div className="flex self-start mb-5 bg-gray-200 rounded-2xl p-2">
            <div className="relative">
              <img
                src={chat.image}
                alt="chat-Img"
                className="rounded-lg w-[200px] h-auto object-cover cursor-pointer"
                onClick={() => {
                  Action.changeModalImage(dispatch, {
                    showModal: true,
                    title: "chat-Img",
                    img: chat.image
                  });
                }}
              />
              <div className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                {chat.time}
              </div>
            </div>
          </div>

          <div className="flex self-start mb-5 bg-gray-200 rounded-2xl p-2">
            <div className="relative">
              <video
                src={chat.video}
                controls
                className="rounded-lg w-[250px] h-auto object-cover"
              />
              <div className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                {chat.time}
              </div>
            </div>
          </div>

          <div className="flex self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2 gap-5">
            <div className="">{chat.message}</div>
            <div className="self-end text-[8px]">{chat.time}</div>
          </div>

          <div className="flex self-end mb-5 bg-green-chat-own rounded-2xl w-fit p-2 gap-5">
            <div className="self-auto">balasan pesan</div>
            <div className="self-end text-[8px]">{chat.time}</div>
          </div>

          <div className="flex self-end mb-5 bg-green-chat-own rounded-2xl p-2">
            <div className="relative">
              <img
                src={chat.image}
                alt="chat-Img"
                className="rounded-lg w-[200px] h-auto object-cover cursor-pointer"
                onClick={() => {
                  Action.changeModalImage(dispatch, {
                    showModal: true,
                    title: "chat-Img",
                    img: chat.image
                  });
                }}
              />
              <div className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                {chat.time}
              </div>
            </div>
          </div>

          <div className="flex self-end mb-5 bg-green-chat-own rounded-2xl p-2">
            <div className="relative">
              <video
                src={chat.video}
                controls
                className="rounded-lg w-[250px] h-auto object-cover"
              />
              <div className="absolute bottom-1 right-2 text-[8px] text-grey-button bg-opacity-70 px-1 rounded">
                {chat.time}
              </div>
            </div>
          </div>

          <div className="self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2">
            <div className="">{chat.message}</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2">
            <div className="">{chat.message}</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2">
            <div className="">{chat.message}</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2">
            <div className="">{chat.message}</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2">
            <div className="">{chat.message}</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2">
            <div className="">{chat.message}</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>

          <div className="self-start mb-5 bg-gray-200 rounded-2xl w-fit p-2">
            <div className="">{chat.message}</div>
          </div>

          <div className="self-end mb-5 bg-green-chat-own rounded-2xl w-fit">
            <div className="self-auto p-2">balasan pesan</div>
          </div>
        </div>

        {/* chat sender */}
        <ChatSender />
      </div>

      {modalImage ? (
        <ModalImage
          onCancel={({ showModal }) => { (Action.changeModalImage(dispatch, { showModal: showModal })); }}
          title={image.title}
          img={image.src} />
      ) : ""}
    </>
  );
};

const DetailChat = ({ chat }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  return (
    <Provider store={store}>
      <MainComponent chat={chat} />
    </Provider>
  );
};

export default DetailChat;
