import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "accountdetails",
  initialState: {
    validateStatus: false,
    validatePass: false,
    accountDetails: {
      name: "",
      photo: "",
      namePhoto: "",
      email: "",
      phoneNumber: "",
      countryCodeId: "",
      address: "",
      provincesId: "",
      citiesId: "",
      districtsId: "",
      postalCode: "",
    },
    selfPickupAvailability: false,
    data: {},
    edit: { email: false, phoneNumber: false, photo: false },
    errorAccountDetails: {},
    codephone: [],
    showError: false
  },
  reducers: {
    setAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setSelfPickupAvailability: (state, action) => {
      state.selfPickupAvailability = action.payload;
    },
    setErrorAccountDetails: (state, action) => {
      state.errorAccountDetails = action.payload;
    },
    setValidateStatus: (state, action) => {
      state.validateStatus = action.payload;
    },
    setValidatePass: (state, action) => {
      state.validatePass = action.payload;
    },
    setCodePhone: (state, action) => {
      state.codephone = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    setShowErrorOn: (state) => {
      state.showError = true;
    },
    setShowErrorOff: (state) => {
      state.showError = false;
    }
  },
});

export const {
  setAccountDetails,
  setSelfPickupAvailability,
  setErrorAccountDetails,
  setValidateStatus,
  setValidatePass,
  setCodePhone,
  setData,
  setEdit,
  setShowErrorOff,
  setShowErrorOn
} = reducerSlice.actions;

export default reducerSlice;
