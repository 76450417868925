import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "autoreply",
  initialState: {
    message: "",
    isLoadingAutoReply: true,
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setIsloadingAutoReply: (state, action) => {
      state.isLoadingAutoReply = action.payload
    }
  },
});

export const {
  setMessage,
  setIsloadingAutoReply
} = reducerSlice.actions;

export default reducerSlice;
