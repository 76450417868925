function ListChatModel({
  id,
  message,
  name,
  avatar,
  time,
  unreadCount,
}) {
  let obj = {};
  obj.id = id;
  obj.message = message;
  obj.name = name;
  obj.avatar = avatar;
  obj.time = time;
  obj.unreadCount = unreadCount;
  return obj;
}

export default ListChatModel;
