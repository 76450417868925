import axios from "axios";
import GeneralUtility from "../utils/general-utility";
import EncryptDecrypt from "./encrypt-decrypt";
import Endpoint from "./endpoint";
import Cookies from "js-cookie";
import UseAuth from "../pages/auth/Auth";
import RouteName from "./routename";
const BASE_API = process.env.REACT_APP_BASE_API;

// kirim Accept-Language lowercase khusus di endpoint FAQ saja.
const cutEndpointFAQ = (url) => {
  let result = url.split("/tanam-emas-be")[1];
  return result;
};

const formatLowerCase = (url, language) => {
  const endpoint = cutEndpointFAQ(url);
  if (endpoint === "/api/v1/public/faq") {
    return language.toLowerCase();
  }
  return language;
};

export default async function CallApi({ url, method, data }) {
  // check status maintenance
  const responseStatus = await axios({
    url: BASE_API + Endpoint.statusMaintenance,
    method: "POST"
  });
  const cookieMaintenance = Cookies.get("status_maintenance");
  const isMaintenance = cookieMaintenance ? EncryptDecrypt.dec(cookieMaintenance) : null;
  if (!isMaintenance) {
    const status = responseStatus.data.data;
    const currentPath = window.location.pathname;
    if (status === false && currentPath !== RouteName.freezemode) {
      UseAuth.removeAllInternalStorage();
      window.location.href = RouteName.freezemode;
      return;
    }
  }

  // get token:
  const token = await GeneralUtility.getToken();
  // get language
  const language = await GeneralUtility.getLanguage();

  const headers = {
    authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": formatLowerCase(url, language),
  };

  const response = await axios({ url, method, data, headers }).catch(
    (err) => err.response
  );

  if (
    response.status === 401 &&
    response.data.message === "You Are Not Authorized"
  ) {
    //Unauthorized or expired token
    const refresh = await refreshToken(url, method, data, headers);
    return refresh;
  } else if (response.status > 300) {
    return handleErrorStatus(response);
  } else {
    const signatureResponse = response
      ? EncryptDecrypt.encSignature(response.request.responseText)
      : "";

    const signature = response.headers["signature"];
    if (signatureResponse !== signature) {
      // return UseAuth.logout();
      console.log("signature beda");
    }

    const res = {
      error: false,
      status: response.status,
      message: response.data.message,
      data: response.data,
      trace_id: response.headers["x-trace-id"]
    };
    return res;
  }
}

function handleErrorStatus(response) {
  let res = {
    error: true,
    status: "",
    message: "",
    data: "",
    error_message: "",
    trace_id: ""
  };

  if (response.status === 400) {
    //bad request
    res = {
      error: true,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
      error_message: response.data.errors,
      trace_id: response.headers["x-trace-id"]
    };
  } else if (response.status === 500) {
    //internal server error
    res = {
      error: true,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
      error_message: response.data.errors,
      trace_id: response.headers["x-trace-id"]
    };
  } else {
    res = {
      error: true,
      status: response.status,
      message: response.data.message,
      data: response.data.data,
      error_message: response.data.errors,
      trace_id: response.headers["x-trace-id"]
    };
  }
  return res;
}

const refreshToken = async (url, method, data, headers) => {
  const refreshToken = await GeneralUtility.getRefreshToken();

  const hit1 = await axios({
    url: BASE_API + Endpoint.refreshToken,
    method: "POST",
    data: { refreshToken: refreshToken },
    headers,
  }).catch((err) => err.response);

  if (hit1.status === 200) {
    Cookies.set(
      "tokenMerchant",
      EncryptDecrypt.enc(hit1.data.data.auth.token, { expires: 3 })
    );
    Cookies.set(
      "refreshTokenMerchant",
      EncryptDecrypt.enc(hit1.data.data.auth.refreshToken, { expires: 3 })
    );

    headers.authorization = `Bearer ${hit1.data.data.auth.token}`;
    const hitAgain = await axios({
      url: url,
      method: method,
      data: data,
      headers: headers,
    }).catch((err) => err.response);

    if (hitAgain.status === 200) {
      const res = {
        error: false,
        status: hitAgain.status,
        message: hitAgain.data.message,
        data: hitAgain.data,
        trace_id: hitAgain.headers["x-trace-id"]
      };
      return res;
    } else {
      return window.location.reload();
    }
  } else {
    window.location = RouteName.login;
    return UseAuth.logout();
  }
};
