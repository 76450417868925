import React from 'react';

const BarDetailChat = ({ chat }) => {
    return (
        <div className="flex items-center p-2 border-b border-gray-200 mb-5">
            <div className="w-11 h-11 flex-shrink-0 rounded-full bg-gray-200 flex items-center justify-center text-xl font-bold text-white">
                {chat.avatar ? (
                    <img
                        src={chat.avatar}
                        alt={chat.name}
                        className="rounded-full w-full h-full object-cover"
                    />
                ) : (
                    chat.name[0]
                )}
            </div>

            <div className="ml-3 flex-1 overflow-y-auto">
                <div className="flex justify-between items-center">
                    <div className="text-green-secondary font-bold">
                        {chat.name}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BarDetailChat;