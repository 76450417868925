import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import { Provider, useDispatch, useSelector } from "react-redux";
import UploadImage from "../../../../components/UploadImage/UploadImage";
import StoreHelper from "../../../../services/store-helper";
import Action from "./redux/Action";
import reducerSlice, {
  setEdit,
  setValidatePass,
  setAccountDetails,
  setShowErrorOn,
  setShowErrorOff,
} from "./redux/Reducer";
import PopupPassword from "../../../../components/PopupPassword/PopupPassword";
import ButtonWhite from "../../../../components/ButtonWhite/ButtonWhite";
import Select from "react-select";
import PopupUtility from "../../../../utils/popup-utility";

const URL_IMG = process.env.REACT_APP_ASSETS_IMAGE;

const MainComponent = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const accountDetails = useSelector((state) => state.reducer.accountDetails);
  const codephone = useSelector((state) => state.reducer.codephone);
  const validateStatus = useSelector((state) => state.reducer.validateStatus);
  const validatePass = useSelector((state) => state.reducer.validatePass);
  const data = useSelector((state) => state.reducer.data);
  const edit = useSelector((state) => state.reducer.edit);
  const selfPickupAvailability = useSelector((state) => state.reducer.selfPickupAvailability);
  const errorAccountDetails = useSelector(
    (state) => state.reducer.errorAccountDetails
  );

  const handleValidation = () => {
    if (validateStatus === true) {
      Action.validate(dispatch, accountDetails);
    } else {
      Action.getCodePhone(dispatch);
      Action.getData(dispatch);
    }
  };

  useEffect(() => {
    handleValidation();
  }, [accountDetails]);

  
  const handleData = () => {
    return selfPickupAvailability
  } 
  
  useEffect(() => {
    handleData();
  },[]);

  return (
    <form encType="multipart/form-data">
      <div className="flex flex-col gap-y-4 md:h-[43em] px-3 overflow-y-auto font-medium">
        {/* merchant photo */}
        <div className="flex flex-row gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">
              {t("register.merchant_photo")}
            </div>
            <div className="opacity-40">
              {t("general.support_files")} jpeg,jpg,png
            </div>
            <div className="opacity-40">{t("general.max_size")} 1Mb</div>
          </div>
          <div className="flex-1">
            <UploadImage
              img={data.photo}
              aspectRatio={1 / 1}
              name={"photo"}
              width={40}
              height={40}
              position={"col"}
              onClick={(photo, name) =>
                dispatch(
                  setAccountDetails({
                    ...accountDetails,
                    photo: photo,
                    namePhoto: name,
                  })
                )
              }
              onDelete={() =>
                dispatch(
                  setAccountDetails({
                    ...accountDetails,
                    photo: "",
                    namePhoto: "",
                  })
                )
              }
            />
            <p className="text-red-500">{errorAccountDetails.photo}</p>
          </div>
        </div>
        <hr />

        {/* merchant name */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">
              {t("register.merchant_name")}
              {/* <span className="text-gold-secondary">*</span> */}
            </div>
          </div>
          <div className="flex-1 text-md">{data.name}</div>
        </div>
        <hr />

        {/* email */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold flex items-center">
              {t("register.email")}
              {/* <span className="text-gold-secondary">*</span> */}

              <img
                src={URL_IMG + "information.png"}
                className="w-4 ml-2 cursor-pointer"
                onClick={() => {
                  PopupUtility.success(t("accountsettings.email_information"), "", 3000);
                }} />
            </div>
          </div>
          <div className="flex-1 text-md opacity-50">{data.emailOwner}</div>
        </div>
        <hr />

        {/* merchant email */}
        {edit.email === false ? (
          <div className="flex items-center gap-x-3">
            <div className="w-44">
              <div className="text-md font-bold">
                {t("register.merchant_email")}
                {/* <span className="text-gold-secondary">*</span> */}
              </div>
            </div>
            <div className="flex text-md">{data.email}</div>
            <div className="flex-1 text-md text-right font-bold">
              <div
                className="cursor-pointer underline underline-offset-2 hover:text-black"
                onClick={() => {
                  dispatch(setEdit({ ...edit, email: true }));
                }}>
                Edit
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center gap-x-3">
            <div className="w-44">
              <div
                className={`text-md font-bold ${errorAccountDetails.email ? "pb-4" : " "
                  }`}>
                {t("register.merchant_email")}
                {/* <span className="text-gold-secondary">*</span> */}
              </div>
            </div>
            <div className="flex-1">
              <input
                type="email"
                name="email"
                onChange={(e) => {
                  Action.handleChange(dispatch, e, accountDetails);
                }}
                placeholder={t("register.input_email_merchant")}
                className={`rounded-xl w-full text-sm font-bold bg-white 
                placeholder:text-green-secondary placeholder:font-medium 
                  ${errorAccountDetails.email
                    ? "border-red-500 border-1"
                    : "border-0"
                  }`}
                defaultValue={data.email}
              />
              <p className="text-red-500">{errorAccountDetails.email}</p>
            </div>
          </div>
        )}
        <hr />

        <div className="flex flex-row items-center gap-x-2">
          <div className="w-44">
            <div
              className={`text-md font-bold ${errorAccountDetails.selfpickupCompability ? "pb-4" : " "
                }`}
            >
              {t("register.selfpickup_Availability")}
              <span className="text-gold-secondary">*</span>
            </div>
          </div>
          <div className="pl-1 mt-5 mb-5">
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                className="sr-only peer"
                type="checkbox"
                name="selfPickupAvailability"
                
                onChange={(e) => {
                  Action.changeSelfpickupCompability(dispatch, e);
                  
                  
                  {/*
                  if (e.target.checked === false) {
                  
                    //Action.changeSelfpickupCompability(dispatch, e);
                  } else {
                    
                    //Action.changeSelfpickupCompability(dispatch, e);
                  }
                */}
                }}
                checked={selfPickupAvailability}
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-primary"></div>
            </label>
          </div>

          
        </div>

        {/* phone number */}
        {edit.phoneNumber === false ? (
          <div className="flex items-center gap-x-3">
            <div className="w-44">
              <div className="text-md font-bold">
                {t("register.phone_no")}
                {/* <span className="text-gold-secondary">*</span> */}
              </div>
            </div>
            <div className="flex text-md">
              {data.countryCode + " " + data.phoneNumber}
            </div>
            <div className="flex-1 text-md text-right font-bold">
              <div
                className="cursor-pointer underline underline-offset-2 hover:text-black"
                onClick={() => {
                  dispatch(setEdit({ ...edit, phoneNumber: true }));
                }}
              >
                Edit
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center gap-x-3">
            <div className="w-44">
              <div
                className={`text-md font-bold ${errorAccountDetails.phoneNumber ||
                  errorAccountDetails.countryCodeId
                  ? "pb-4"
                  : " "
                  }`}
              >
                {t("register.phone_no")}
                {/* <span className="text-gold-secondary">*</span> */}
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center gap-x-2">
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errorAccountDetails.phoneNumber
                        ? "red"
                        : "white",
                    }),
                  }}
                  className="text-sm border w-36 bg-white font-medium"
                  defaultValue={{
                    label: data.countryCode,
                    value: data.countryCodeId,
                  }}
                  options={codephone}
                  name="countryCodeId"
                  onChange={(item) => {
                    Action.handleChange(
                      dispatch,
                      {
                        target: { name: "countryCodeId", value: item.value },
                      },
                      accountDetails
                    );
                  }}
                // onChange={(e) => {
                //   Action.handleChange(dispatch, e, accountDetails);
                // }}
                />
                {/* <select
                  className={`text-sm border rounded-xl w-28 bg-white font-medium ${
                    errorAccountDetails.countryCodeId
                      ? "border-red-500"
                      : "border-gray-primary"
                  }`}
                  name="countryCodeId"
                  defaultValue={data.countryCodeId}
                  onChange={(e) => {
                    Action.handleChange(dispatch, e, accountDetails);
                  }}
                >
                  <option value="default" disabled>
                    Choose
                  </option>
                  {codephone.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.dialCode}
                    </option>
                  ))}
                </select> */}
                <input
                  type="text"
                  name="phoneNumber"
                  onChange={(e) => {
                    Action.handleChange(dispatch, e, accountDetails);
                  }}
                  placeholder={t("register.input_phone_no")}
                  className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errorAccountDetails.phoneNumber
                    ? "border-red-500 border-1"
                    : "border-0"
                    }`}
                  value={accountDetails.phoneNumber}
                />
              </div>
              <p className="text-red-500">{errorAccountDetails.phoneNumber}</p>
            </div>
          </div>
        )}
        <hr />

        {/* address */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">
              {t("register.address")}
              {/* <span className="text-gold-secondary">*</span> */}
            </div>
          </div>
          <div className="flex-1 text-md">{data.address}</div>
        </div>
        <hr />

        {/* province */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">
              {t("register.province")}
              {/* <span className="text-gold-secondary">*</span> */}
            </div>
          </div>
          <div className="flex-1 text-md">{data.provinces}</div>
        </div>
        <hr />

        {/* city */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">
              {t("register.city")}
              {/* <span className="text-gold-secondary">*</span> */}
            </div>
          </div>
          <div className="flex-1 text-md">{data.cities}</div>
        </div>
        <hr />

        {/* district */}
        <div className="flex flex-row items-center gap-x-3">
          <div className="w-44">
            <div className="text-md font-bold">
              {t("register.district")}
              {/* <span className="text-gold-secondary">*</span> */}
            </div>
          </div>
          <div className="flex-1 text-md">{data.districts}</div>
        </div>
        <hr />

        {/* postalcode */}
        <div className="flex flex-row items-center gap-x-3 pb-2">
          <div className="w-44">
            <div className="text-md font-bold">
              {t("register.postalcode")}
              {/* <span className="text-gold-secondary">*</span> */}
            </div>
          </div>
          <div className="flex-1 text-md">{data.postalCode}</div>
        </div>
        <hr />

        <div className="flex flex-row-reverse gap-x-2 pt-5 pb-3">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch(setValidatePass(true));
              dispatch(setShowErrorOff());
              // Action.handleSubmit(dispatch, accountDetails, data, e);
            }}
            className="button md:w-36 hover:bg-gray-primary text-white border-2 hover:text-green-quaternary border-green-secondary text-xs tracking-widest bg-green-secondary font-bold py-3 rounded-xl"
          >
            {t("general.save").toUpperCase()}
          </button>
          <ButtonWhite
            title={t("general.cancel").toUpperCase()}
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                setEdit({ phoneNumber: false, email: false, photo: false })
              );
            }}
          />
        </div>
      </div>

      {validatePass === true ? (
        <PopupPassword
          onCancel={() => {
            dispatch(setValidatePass(false));
          }}
          onSubmit={(pass, e) => {
            Action.checkValid(dispatch, pass, e, accountDetails, data, selfPickupAvailability);
          }}
          section={t("register.account_details")}
        />
      ) : (
        ""
      )}
    </form>
  );
};

const AccountDetails = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();
  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default AccountDetails;

