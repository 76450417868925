import React, { useRef, useState } from "react";
import { useTranslation } from "react-multi-lang";
import ButtonWhite from "../ButtonWhite/ButtonWhite";
import ButtonGreen from "../ButtonGreen/ButtonGreen";

const UploadMedia = ({ onClose, type, position, wording, name }) => {
  const t = useTranslation();
  const fileInputRef = useRef(null);
  const baseImg = process.env.REACT_APP_ASSETS_IMAGE;
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  console.log("prev", previewUrl);

  const maxImageSize = 2 * 1024 * 1024; // 2MB
  const maxVideoSize = 15 * 1024 * 1024; // 10MB

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file image or video
    const fileType = file.type.split("/")[0];
    const fileSize = file.size;

    if (fileType === "image" && fileSize > maxImageSize) {
      setErrorMessage("Image size exceeds 2MB.");
      setSelectedFile(null);
    } else if (fileType === "video" && fileSize > maxVideoSize) {
      setErrorMessage("Video size exceeds 10MB.");
      setSelectedFile(null);
    } else {
      setErrorMessage("");
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = () => {
    if (!selectedFile) {
      setErrorMessage(t("chats.browser_files"));
      return;
    }
    console.log("File", selectedFile);

    //onClose();
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center justify-center">
        <h2 className="text-lg text-left font-bold mb-4">
          Upload Image or Video
        </h2>
        <div
          className={`h-40 ${
            type === "rectangle" ? "w-full" : "w-full"
          } flex flex-col items-center justify-center mb-3`}
        >
          {previewUrl === null ? (
            <label
              className="h-full w-full flex flex-col items-center justify-center border-2 border-gray-400 rounded-xl border-opacity-20 cursor-pointer bg-gray-primary dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-white dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              htmlFor={name}
            >
              <div
                className={`flex flex-${position} gap-${
                  position === "col" ? "y" : "x"
                }-3 items-center justify-center `}
              >
                <img
                  className="h-10  items-center cursor-pointer mb-2 justify-center"
                  src={baseImg + "upload-icon.svg"}
                  alt="upload"
                  onClick={handleImageClick}
                />
                {wording ? (
                  <p className="mb-2 text-sm font-semibold text-center">
                    {wording}
                  </p>
                ) : (
                  <p className="mb-2 text-sm font-semibold text-center">
                    <span className="underline underline-offset-2 text-green-secondary">
                      {" "}
                      {t("appcustomization.browser_files")}
                    </span>
                  </p>
                )}
              </div>
            </label>
          ) : (
            ""
          )}
          <input
            id={name}
            type="file"
            accept="image/*,video/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            className="hidden"
          />

          {/* Preview */}
          {previewUrl && (
            <div className="mb-3">
              {selectedFile.type.startsWith("image") ? (
                <img
                  src={previewUrl}
                  alt="preview"
                  className="rounded-lg max-w-[150px] max-h-[150px] object-contain"
                />
              ) : (
                <video
                  src={previewUrl}
                  controls
                  className="rounded-lg max-w-[150px] max-h-[150px] object-contain"
                />
              )}
            </div>
          )}
        </div>

        {errorMessage && (
          <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
        )}

        <div className="flex justify-end gap-2">
          <ButtonWhite
            title={t("chats.cancel")}
            onClick={() => {
              onClose({ showMedia: false });
            }}
          />

          <ButtonGreen title={t("chats.upload")} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default UploadMedia;
