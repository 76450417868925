import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import ButtonWhite from "../../../../components/ButtonWhite/ButtonWhite";
import ButtonGrey from "../../../../components/ButtonGrey/ButtonGrey";
import ButtonGreen from "../../../../components/ButtonGreen/ButtonGreen";
import Action from "./redux/Action";
import { Provider, useDispatch, useSelector } from "react-redux";
import StoreHelper from "../../../../services/store-helper";
import reducerSlice from "./redux/Reducer";
import Skeleton from "react-loading-skeleton";

const AutoReply = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const dispatch = useDispatch();
  const t = useTranslation();
  const message = useSelector((state) => state.reducer.message);
  const isLoadingAutoReply = useSelector((state) => state.reducer.isLoadingAutoReply)

  useEffect(() => {
    Action.getAutoReply(dispatch)
  }, [])
 
  return (
    <div className="flex px-3 md:h-[37em] gap-x-3">
      <div className="basis-3/12 text-green-primary">
        <span className="font-bold text-lg">{t("chats.auto_reply_chat")}</span>
        <p className="mt-3">
        {t("chats.auto_reply_chat_desc")}
          
        </p>
      </div>
      <div className="basis-9/12 relative">
        {/* 
        <input
          className="w-full h-24 rounded-lg border-none flex-grow p-3 focus:outline-none"
          placeholder="Input auto reply chat"
        /> */}
        <div className="relative">
          {isLoadingAutoReply ? (
            <Skeleton height="96px" />
          ) : (
          <textarea
            className="w-full h-24 text-sm rounded-lg border border-gray-300 p-3 focus:outline-none resize-none"
            placeholder="Input auto reply chat "
            value={message}
            name="autoReply"
            onChange={(e)=>{
              Action.autoReply(dispatch, e)
            }}
            maxLength={250}
          />
          )}
          
          <div className="absolute bottom-2 right-3 text-gray-500 text-sm">
            
          {(message ? message.length : 0)}/250
          </div>
        </div>
        <div className="flex flex-row-reverse gap-x-2 pt-5 pb-3">
          
          <ButtonGreen
          title={t("general.save").toUpperCase()}
          onClick={(e) => {
            Action.saveAutoReply(dispatch, message)
          }} />
          <ButtonWhite 
            title={t("products.cancel").toUpperCase()}
            onClick={(e) => {
              
            }}
          />
        </div>
      </div>
      <div></div>
    </div>
  );
  }

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
  
};

export default AutoReply;
