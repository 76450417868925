import RegisterRepo from "../../../../repositories/RegisterRepo";
import RouteName from "../../../../services/routename";
import PopupUtility from "../../../../utils/popup-utility";
import {
  setCity,
  setDistrict,
  setPage,
  setCodePhone,
  setProvince,
  setErrorAccountDetails,
  setErrorBankDetails,
  setBank,
  setErrorLegalDocuments,
  setErrorSecurity,
  setValidateStatus,
  setErrorTermsconditions,
  setLoading,
  setTermscoditionsDesc,
  setNextoToBankDetails,
  setOpenZipCode,
  setSelfpickupCompability,
} from "./Reducer";

const getTnC = async (dispatch) => {
  const tnc = await RegisterRepo.getTnC({
    key: "REGISTER_MERCHANT",
  });
  dispatch(setTermscoditionsDesc(tnc.data.data.value));
};

const getCodePhone = async (dispatch) => {
  const phoneNumber = await RegisterRepo.getCodePhone();
  dispatch(setCodePhone(phoneNumber));
};

const getProvince = async (dispatch) => {
  const province = await RegisterRepo.getProvince();
  dispatch(setProvince(province));
};

const getCity = async (dispatch, id) => {
  let dat = { id: id };
  const city = await RegisterRepo.getCity(dat);
  dispatch(setCity(city));
};

const getBank = async (dispatch) => {
  const bank = await RegisterRepo.getBank();
  dispatch(setBank(bank));
};

const getDistrict = async (dispatch, id) => {
  let dat = { id: id };
  const district = await RegisterRepo.getDistrict(dat);
  dispatch(setDistrict(district));
};

const validateStep1 = (dispatch, values) => {
  const errors = {};

  if (!values.photo) {
    errors.photo = "Merchant Photo is required!";
  }
  if (!values.name) {
    errors.name = "Merchant name is required!";
  }
  if (!values.email) {
    errors.email = "Email Merchant is required!";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email format!";
  }
  if (!values.ownerEmail) {
    errors.ownerEmail = "Email is required!";
  } else if (!isValidEmail(values.ownerEmail)) {
    errors.ownerEmail = "Invalid email format!";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "phoneNumber number is required!";
  } else if (!values.countryCodeId) {
    errors.phoneNumber = "Code National Number is required!";
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = "Phone number must be at least 10 numbers";
  }
  if (!values.countryCodeId) {
    errors.countryCodeId = "Code National phoneNumber is required!";
  }
  if (!values.address) {
    errors.address = "Address is required!";
  }
  if (!values.provincesId) {
    errors.provincesId = "Province is required!";
  }
  if (!values.citiesId) {
    errors.citiesId = "City is required!";
  }
  if (!values.districtsId) {
    errors.districtsId = "District is required!";
  }
  if (!values.postalCode) {
    errors.postalCode = "Postal Code is required!";
  }

  dispatch(setErrorAccountDetails(errors));
  return errors;
};

// Helper function to validate email format
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


const validateStep2 = (dispatch, values) => {
  const errors = {};
  if (!values.bankName) {
    errors.bankName = "Bank Account Name is required!";
  }
  if (!values.bankId) {
    errors.bankId = "Bank Name is required!";
  }
  if (!values.bankNumber) {
    errors.bankNumber = "Bank Account Number is required!";
  }
  dispatch(setErrorBankDetails(errors));
  return errors;
};

const validateStep3 = (dispatch, values) => {
  const errors = {};
  if (!values.ktpFile) {
    errors.ktpFile = "KTP is required!";
  }
  if (!values.ktpNik) {
    errors.ktpNik = "NIK is required!";
  } else if (values.ktpNik.length < 16 || values.ktpNik.length > 16) {
    errors.ktpNik = "KTP must 16 number!";
  }

  // if (!values.npwpFile) {
  //   errors.npwpFile = "NPWP is required!";
  // }

  if (values.npwpNumber) {
    if (values.npwpNumber.length < 15 || values.npwpNumber.length > 16) {
      errors.npwpNumber = "NPWP Number must 15-16 number!";
    }
  }
  // errors.npwpNumber = "NPWP Number is required!";
  if (!values.merchantNib) {
    errors.merchantNib = "NIB is required!";
  } else if (values.merchantNib.length < 13 || values.merchantNib.length > 13) {
    errors.merchantNib = "NIB must 13 number!";
  }
  dispatch(setErrorLegalDocuments(errors));
  return errors;
};

const validateStep4 = (dispatch, values) => {
  const errors = {};

  if (values.password.length < 8) {
    errors.char = "Password must at least 8 number!";
  }
  if (values.password.search(/[0-9]/) < 0) {
    errors.number = "Password must contain at least one number!";
  }
  if (values.password.search(/[a-z]/) < 0) {
    errors.lowercase = "Password must contain at least one lowercase!";
  }
  if (values.password.search(/[A-Z]/) < 0) {
    errors.uppercase = "Password must contain at least one uppercase!";
  }

  if (values.confirm_password !== values.password) {
    errors.confirm_password = "Password doesnt match!";
  }

  dispatch(setErrorSecurity(errors));
  return errors;
};

const validateStep5 = (dispatch, values) => {
  const errors = {};
  if (!values.tnc) {
    errors.tnc = "Terms and condition is required!";
  }
  if (!values.dng) {
    errors.dng = "This is required!";
  }
  dispatch(setErrorTermsconditions(errors));
  return errors;
};

const getPage = async (dispatch, page) => {
  dispatch(setPage(page));
  dispatch(setValidateStatus(false));
};

const handleSignup = async (
  dispatch,
  navigate,
  accountDetails,
  bankDetails,
  legalDocuments,
  security,
  termscoditions,
  selfpickupCompability
) => {
  let data = {
    ...accountDetails,
    ...bankDetails,
    ...legalDocuments,
    ...security,
    ...termscoditions,
    selfpickupCompability,
  };
  console.log("data", data)
  dispatch(setLoading(true));
  const res = await RegisterRepo.signUp(data);

  if (res.error === false) {
    let response = res.data.data;
    if (response.merchantStatus === -2) {
    } else if (response.merchantStatus === -1) {
      navigate(RouteName.merchantreject);
    } else if (response.merchantStatus === 0) {
      window.location = RouteName.dashboard;
    } else if (response.merchantStatus === 1) {
      window.location = RouteName.dashboard;
    } else if (
      response.merchantStatus === 2 ||
      response.merchantStatus === 3 ||
      response.merchantStatus === 4
    ) {
      navigate(RouteName.merchantapplication, {
        state: { merchantStatus: response },
      });
    }
  } else {
    PopupUtility.responseValidate("Signup Failed", res.message);
  }
  dispatch(setLoading(false));
};

const handleOpenZipCode = (dispatch) => {
  dispatch(setOpenZipCode(true));
  dispatch(setNextoToBankDetails(false));
};

const handleCancelZipCode = (dispatch) => {
  dispatch(setOpenZipCode(false));
  dispatch(setNextoToBankDetails(false));
};

const handleNextToBankDetails = (dispatch) => {
  dispatch(setOpenZipCode(false));
  dispatch(setNextoToBankDetails(true));
};

const changeSelfpickupCompability = async (dispatch, e) => {
  const checked = e.target.checked;
  dispatch(setSelfpickupCompability(checked));
}
const Action = {
  getTnC,
  getCodePhone,
  getProvince,
  getCity,
  getDistrict,
  getPage,
  getBank,
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
  validateStep5,
  handleSignup,
  handleCancelZipCode,
  handleNextToBankDetails,
  handleOpenZipCode,
  changeSelfpickupCompability,
};

export default Action;
