import React from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTermsconditions, setValidateStatus } from "../redux/Reducer";
import Action from "../redux/Action";
import ButtonGold from "../../../../components/ButtonGold/ButtonGold";
import { useEffect } from "react";

const TermsConditions = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.reducer.loading);
  const termscoditions = useSelector((state) => state.reducer.termscoditions);
  const termscoditionsDesc = useSelector(
    (state) => state.reducer.termscoditionsDesc
  );
  const accountDetails = useSelector((state) => state.reducer.accountDetails);
  const bankDetails = useSelector((state) => state.reducer.bankDetails);
  const legalDocuments = useSelector((state) => state.reducer.legalDocuments);
  const security = useSelector((state) => state.reducer.security);
  const errorTermsconditions = useSelector(
    (state) => state.reducer.errorTermsconditions
  );
  const selfpickupCompability = useSelector((state) => state.reducer.selfpickupCompability);
  console.log("depan", selfpickupCompability)
  const handleChange = (e) => {
    const { name, checked } = e.target;
    dispatch(
      setTermsconditions({
        ...termscoditions,
        [name]: checked,
      })
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setValidateStatus(true));
    const isValid = Action.validateStep5(dispatch, termscoditions);
    if (Object.keys(isValid).length === 0) {
      Action.handleSignup(
        dispatch,
        navigate,
        accountDetails,
        bankDetails,
        legalDocuments,
        security,
        termscoditions,
        selfpickupCompability,
      );
    }
  };

  useEffect(() => {
    Action.getTnC(dispatch);
  }, []);

  return (
    <form>
      <div className="flex flex-col gap-y-4 md:h-[30em] overflow-y-auto bg-white p-5 rounded-xl">
        <div className="font-bold text-md">
          {t("register.terms_conditions")}
        </div>

        <div
          className="font-regular"
          dangerouslySetInnerHTML={{ __html: termscoditionsDesc }}
        />

        <div className="flex flex-row">
          <input
            type="checkbox"
            name="tnc"
            className="rounded-md checked:bg-gold-secondary"
            id="tnc"
            onChange={handleChange}
          />
          <label htmlFor="tnc" className="pl-3 font-bold">
            {t("register.agree")}
          </label>
        </div>
        <p className="text-red-500 pl-7">{errorTermsconditions.tnc}</p>

        <div className="flex flex-row">
          <input
            type="checkbox"
            name="dng"
            className="rounded-md checked:bg-gold-secondary"
            id="dng"
            onChange={handleChange}
          />
          <label htmlFor="dng" className="pl-3 font-bold">
            {t("register.pureness")}
          </label>
        </div>
        <p className="text-red-500 pl-7">{errorTermsconditions.dng}</p>
      </div>
      <div className="flex flex-row-reverse gap-x-2 pt-5">
        <ButtonGold
          onClick={(e) => {
            handleSubmit(e);
          }}
          title={t("register.next").toUpperCase()}
          disabled={loading}
          loading={loading}
        />
      </div>
    </form>
  );
};

export default TermsConditions;
