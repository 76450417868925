import ChatRepository from "../../../repositories/ChatRepository";
import {
  setData,
  setSelectedItem,
  setIsUploadOpen,
  setBodyChat,
  setModalImage,
  setImage,
  setLoaderListChat
} from "./Reducer";

const getList = async (dispatch) => {
  dispatch(setLoaderListChat(true));
  let response = await ChatRepository.getListData();
  if (response && Array.isArray(response.data)) {
    dispatch(setData(response.data));
  } else {
    console.error('Response data is not an array:', response.data);
  }
  dispatch(setLoaderListChat(false));
};

const handleBodyChat = (dispatch, e) => {
  const { value } = e.target;
  dispatch(setBodyChat(value));
};

const handleSelectedChat = (dispatch, chat) => {
  dispatch(setSelectedItem(chat));
};

const uploadMedia = (dispatch, { showMedia }) => {
  dispatch(setIsUploadOpen(showMedia));
};

const changeModalImage = async (dispatch, { showModal, title, img }) => {
  dispatch(setModalImage(showModal));
  dispatch(setImage({ title: title, src: img }));
};
const Action = {
  getList,
  handleSelectedChat,
  uploadMedia,
  handleBodyChat,
  changeModalImage
};

export default Action;