import { useDispatch, useSelector } from "react-redux";
import Action from "../redux/Action";
import React from "react";
import Skeleton from "react-loading-skeleton";


const ChatList = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reducer.data);
  const loaderListChat = useSelector((state) => state.reducer.loaderListChat);

  const getInitials = (name) => {
    const initials = name
      .split(" ")
      .map((n) => n[0])
      .join("");
    return initials.toUpperCase();
  };

  return (
    <>
      {loaderListChat ? <Skeleton count={8} height="60px" /> : data.length > 0 ? data.map((chat, index) => (
        <React.Fragment key={index}>
          <div
            className={`flex items-center p-2 ${data.length - 1 !== index ? "border-b border-gray-200" : ""} cursor-pointer hover:bg-gray-100`}
            onClick={() => Action.handleSelectedChat(dispatch, chat)}>
            <div className="hidden">{chat.id}</div>
            <div className="w-12 h-12 flex-shrink-0 rounded-full bg-gray-200 flex items-center justify-center text-xl font-bold text-white">
              {chat.avatar ? (
                <img src={chat.avatar} alt={chat.name} className="rounded-full w-full h-full object-cover" />
              ) : (
                getInitials(chat.name)
              )}
            </div>
            <div className="ml-4 flex-1">
              <div className="flex justify-between items-center">
                <div className="font-medium text-gray-900">{chat.name}</div>
                <div className="text-xs text-gray-500">{chat.time}</div>
              </div>
              <div className="flex justify-between items-center">
                <div className="text-sm text-gray-600">{chat.message}</div>
                {chat.unreadCount > 0 &&
                  <div className="flex items-center justify-center w-3 h-3 p-[4px] bg-red-600 text-white text-xs font-bold rounded-sm">
                    {chat.unreadCount}
                  </div>}
              </div>
            </div>
          </div>
          {data.length - 1 !== index && <hr className="mb-0" />}
        </React.Fragment>
      )) : <p className="text-center text-green-primary mt-2">No chat available.</p>}
    </>
  );
};

export default ChatList;

