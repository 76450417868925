function OrdersModel({
  id,
  status,
  invoice,
  customerName,
  dateCreated,
  paymentMethod,
  address,
  courier,
  receiptNumber,
  receivedBy,
  receivedDate,
  product,
  shipping,
  fee,
  insuranceFee,
  complaint,
  total,
  commission,
  startSelfPickupDate,
  endSelfPickupDate,
  pickupBy
}) {
  let obj = {};
  obj.id = id;
  obj.status = status;
  obj.invoice = invoice;
  obj.customerName = customerName;
  obj.dateCreated = dateCreated;
  obj.paymentMethod = paymentMethod;
  obj.address = address;
  obj.courier = courier;
  obj.receiptNumber = receiptNumber;
  obj.receivedBy = receivedBy;
  obj.receivedDate = receivedDate;
  obj.product = product;
  obj.shipping = shipping;
  obj.fee = fee;
  obj.insuranceFee = insuranceFee;
  obj.complaint = complaint;
  obj.total = total;
  obj.commission = commission;
  obj.startSelfPickupDate = startSelfPickupDate;
  obj.endSelfPickupDate = endSelfPickupDate;
  obj.pickupBy = pickupBy;
  return obj;
}

export default OrdersModel;
