import ListChatModel from "../pages/chat/model/ListChatModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import DateUtility from "../utils/date-utility";

const BASE_API = process.env.REACT_APP_BASE_API;

const getListData = async () => {
  const url = BASE_API + Endpoint.list_chat;
  const response = await CallApi({ url, method: "POST" });
  const res1 = response !== "undefined" ? response.data : null;
  const res2 = res1 !== null ? res1.data : null;

  let data = res2.map((item) => {
    return new ListChatModel({
      id: item.id,
      name: item.customerName,
      avatar: null,
      message: item.lastMessage,
      time: DateUtility.formatDate(item.lastMessageDateTime, "full"),
      unreadCount: item.totalUnreadMessage,
    });
  });

  return { data };
};
const getAutoReply = async (data) => {
  const url = BASE_API + Endpoint.getAutoReply;
  const response = await CallApi({url, method: "POST", data});
  const response1 = typeof response != "undefined" ? response.data : null;
  return response1;
}

const saveAutoReply = async (data) => {
  const url = BASE_API + Endpoint.saveAutoReply;
  const response = await CallApi({url, method: "POST", data});
  return response;
}

const ChatRepository = {
  getListData,
  getAutoReply,
  saveAutoReply,
};

export default ChatRepository;
