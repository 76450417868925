import Cookies from "js-cookie";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import EncryptDecrypt from "../services/encrypt-decrypt";
import RouteName from "../services/routename";
import axios from "axios";
import UseAuth from "../pages/auth/Auth";

const BASE_API = process.env.REACT_APP_BASE_API;

const getAuth = async (dat) => {
  const url = BASE_API + Endpoint.login;
  const response = await CallApi({ url, method: "POST", data: dat });
  return response;
};

const forgotPassword = async (data) => {
  const url = BASE_API + Endpoint.forgotPassword;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const resetPassword = async (data) => {
  const url = BASE_API + Endpoint.resetPassword;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const resetPasswordCustomer = async (data) => {
  const url = BASE_API + Endpoint.resetPasswordCustomer;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const refreshToken = async () => {
  const getToken = Cookies.get("refreshToken");
  const refreshToken =
    getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;
  const url = BASE_API + Endpoint.refreshToken;
  const response = await CallApi({
    url,
    method: "POST",
    data: { refreshToken: refreshToken },
  });

  const data = {
    error: response.error,
    message: response.message,
    token: response.data.data.auth.token,
    refreshToken: response.data.data.auth.refreshToken,
  };

  return data;
};

const checkStatusMerchant = async () => {
  const url = BASE_API + Endpoint.checkStatusMerchant;
  const response = await CallApi({ url, method: "POST", data: {} });
  return response;
};

const getStatusMaintentance = async () => {
  const responseStatus = await axios({
    url: BASE_API + Endpoint.statusMaintenance,
    method: "POST"
  });

  const cookieMaintenance = Cookies.get("status_maintenance");
  const isMaintenance = cookieMaintenance ? EncryptDecrypt.dec(cookieMaintenance) : null;
  if (!isMaintenance) {
    const status = responseStatus.data.data;
    const currentPath = window.location.pathname;
    if (status === false && currentPath !== RouteName.freezemode) {
      UseAuth.removeAllInternalStorage();
      window.location.href = RouteName.freezemode;
      return;
    }
    return status;
  }
};

const AuthRepository = {
  getAuth,
  forgotPassword,
  resetPassword,
  resetPasswordCustomer,
  refreshToken,
  checkStatusMerchant,
  getStatusMaintentance
};

export default AuthRepository;
