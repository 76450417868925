import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "New-Order",
  initialState: {
    list: [],
    search: "",
    sort: "",
    startDateFilter: "",
    endDateFilter: "",
    hasMoreItems: true,
    loadingData: false,
    showInvoiceModal: false,
    invoiceDetail: {},
    showTrackingModal: false,
    trackingDetail: {},
    isEmptyList: false,
    page: 0,
    loadingButton: false,
    disabledSetcomplete: false,
    disabledSetcancel: false,
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setStartDateFilter: (state, action) => {
      state.startDateFilter = action.payload;
    },
    setEndDateFilter: (state, action) => {
      state.endDateFilter = action.payload;
    },
    setHasMoreItems: (state, action) => {
      state.hasMoreItems = action.payload;
    },
    setLoadingData: (state, action) => {
      state.loadingData = action.payload;
    },
    setShowInvoiceModal: (state, action) => {
      state.showInvoiceModal = action.payload;
    },
    setInvoiceDetail: (state, action) => {
      state.invoiceDetail = action.payload;
    },
    setShowTrackingModal: (state, action) => {
      state.showTrackingModal = action.payload;
    },
    setTrackingDetail: (state, action) => {
      state.trackingDetail = action.payload;
    },
    setIsEmptyList: (state, action) => {
      state.isEmptyList = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLoadingButton: (state, action) => {
      state.loadingButton = action.payload;
    },
    setDisabledSecomplete: (state, action) => {
      state.disabledSetcomplete = action.payload;
    },
    setDisabledSetcancel: (state, action) => {
      state.disabledSetcancel = action.payload;
    },
  },
});

export const {
  setList,
  setSearch,
  setSort,
  setStartDateFilter,
  setEndDateFilter,
  setHasMoreItems,
  setLoadingData,
  setShowInvoiceModal,
  setInvoiceDetail,
  setShowTrackingModal,
  setTrackingDetail,
  setIsEmptyList,
  setPage,
  setLoadingButton,
  setDisabledSecomplete,
  setDisabledSetcancel,
} = reducerSlice.actions;
export default reducerSlice;
