import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Action from '../redux/Action';
import UploadMedia from '../../../components/ChatMedia/UploadMedia';

const ChatSender = () => {
    const dispatch = useDispatch();

    const isUploadOpen = useSelector((state) => state.reducer.isUploadOpen);
    const bodyChat = useSelector((state) => state.reducer.bodyChat);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                alert("test");
            }
        }} className="bg-gray-primary flex rounded-2xl h-12 items-center p-2 mt-auto absolute bottom-0 left-0 right-0">
            <img
                className="h-10 w-10 fill-slate-300 cursor-pointer"
                src={process.env.REACT_APP_ASSETS_IMAGE + "icon-add-image.png"}
                onClick={() => {
                    Action.uploadMedia(dispatch, { showMedia: true });
                }}
                alt="add-image"
            />
            {isUploadOpen ?
                <UploadMedia
                    position={"col"}
                    onClose={() => {
                        Action.uploadMedia(dispatch, { showMedia: false });
                    }}
                />
                : ""}
            <div className="flex items-center justify-center h-full mx-2">
                <div className="h-6 border-l-2 border-gray-400"></div>
            </div>
            <textarea
                className="bg-gray-primary border-none align-middle flex-grow h-12 text-sm p-2 focus:outline-none resize-none focus:border-transparent focus:ring-0"
                placeholder="Type Something Here"
                onChange={(e) => {
                    if (e.nativeEvent.keyCode === 13) {
                        e.preventDefault();
                        alert("kontol");
                        // if (bodyChat !== "") {
                        //     e.currentTarget.form.dispatchEvent(new Event('submit'));
                        // }
                    } else {
                        Action.handleBodyChat(dispatch, e);
                    }
                }}
            />
            {bodyChat !== "" ? (
                <button type='submit'>
                    <img
                        className="h-10 w-10 fill-slate-300 ml-2"
                        src={process.env.REACT_APP_ASSETS_IMAGE + "chat-icon-send-active.png"}
                        alt="add-image" />
                </button>
            ) : (
                <img
                    className="h-10 w-10 fill-slate-300 ml-2"
                    src={process.env.REACT_APP_ASSETS_IMAGE + "icon-sent-disable.png"}
                    alt="add-image" />
            )}
        </form>
    );
};

export default ChatSender;